@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* Use Tailwind's background classes for light and dark mode */
   @apply bg-gray-50 dark:bg-gray-900;
   @apply font-dm-sans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.font-dm-sans {
  font-family: 'DM Sans', sans-serif;
}

